import axios from 'axios';
import { View, Button,TextInput,StyleSheet,ScrollView,Text,Image } from 'react-native';

import React, { useState , useEffect} from 'react';
import validator from 'validator';


const styles = StyleSheet.create({
    input: {
      height: 40,
      width:250,
      margin: 12,
      borderWidth: 1,
      borderRadius:10,
      padding: 10,
    },
    inputmultiline: {
      height:100,
      width:250,
      margin: 12,
      borderWidth: 1,
      borderRadius:10,
      padding: 10,
    }
  })
  export default function MyTabsHomeCall()
  {

  const [txtName, setName] = React.useState('');
  const [txtEmail, setEmail] = React.useState('');
  const[txtPhNumber,setPhNumber]=React.useState('');
  const [txtMessage,setMessage]=React.useState('');


  /*useEffect(() => {
    if (typeof window !== "undefined") {
     document.title = "Sai Siddha Samathi Yogashram";
      console.log(document.title); // Should output "HomeScreenWindow"
    }
  }, []);

*/
  useEffect(() => {
    window.title = "Home";  // Set the document title
  }, []);

return (
 
  <ScrollView style ={{backgroundColor: 'bisque',height:'auto',flex:1,flexGrow:1}}>
    <View style={{alignContent:'center',justifyContent:'center',flexDirection:'column'}}>


  <View style={{alignContent:'center',justifyContent:'center',flexDirection:'column',alignItems:'center',flex:1, backgroundColor: 'bisque'}}>
  <Image style={{height:550,width:'100%'}} 
   source={require('./Pictures1/Ourstory5.jpg')}/>
   </View>
</View>
   </ScrollView>
); 

}

