import React, { useState,useEffect} from 'react';
import { Text, View, Image, TouchableOpacity, Linking, StyleSheet, Dimensions,ScrollView,Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import 'react-native-gesture-handler';

// Import your components correctly here
import MyTabsOnlineServicesCall from './MyTabsOnlineServices';
import MyTabsOfflineServicesCall from './MyTabsOfflineServices';
import MyTabsOneonOneServicesCall from './MyTabsOneOnOneServices';
import MyTabsRecordedVideoServicesCall from './MyTabsRecordedVideos';
import MyTabsOtherServicesCall from './MyTabsOtherServices';
import MyTabsTestimonialsCall from './MyTabsTestimonials';
import MyTabsLocationsCall from './MyTabsLocations';
import MyTabsTeamCall from './MyTabsTeam';
import MyTabsHomeCall from './MyTabsHome';
import MyTabsHomeCallNext from './MyTabsHomeNext' ;

const DropdownContext = React.createContext();
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const Stack = createStackNavigator();

function DropdownProvider({ children }) {
  return (
    <DropdownContext.Provider value={{}}>
      {children}
    </DropdownContext.Provider>
  );
}

function DropdownMenu({ navigation }) {
  const menuItems = [
    { name: 'Home', component: MyTabsHomeCall},
    { name: 'About Us', component: MyTabsHomeCallNext},
    { name: 'Online Services', component: MyTabsOnlineServicesCall },
    { name: 'Offline Services', component: MyTabsOfflineServicesCall },
    { name: 'One On One', component: MyTabsOneonOneServicesCall },
    { name: 'Recorded Video Service', component: MyTabsRecordedVideoServicesCall },
    { name: 'Other Services', component: MyTabsOtherServicesCall },
    { name: 'Team', component: MyTabsTeamCall },
    { name: 'Testimonial', component: MyTabsTestimonialsCall },
    { name: 'Locations', component: MyTabsLocationsCall },
  ];

  return (
    <View style={styles.dropdown}>
      <View style={styles.dropdownContent}>
        {menuItems.map(item => (
          <TouchableOpacity key={item.name} onPress={() => navigation.navigate(item.name)}>
            <Text style={styles.dropdownItem}>{item.name}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
}

function ScreenTemplate({ Component, navigation }) {
  return (
    <View style={styles.containerScreen}>
      <DropdownMenu navigation={navigation} />
      <Component />
    </View>
  );
}


export default function MyTabCall () {
  
const [mobileNumber, setMobileNumber] = useState('9499010610');


const HandleWhatsappSMS = () => {
  if (mobileNumber.length !== 10) {
    alert('Please insert correct contact number');
    return;
  }
  Linking.openURL(`https://api.whatsapp.com/send?phone=91${mobileNumber}&text=Hi Saisiddhasamathiyogashram.com. This is an enquiry message`)
    .then((data) => {
      console.log('WhatsApp Opened');
    })
    .catch(() => {
      alert('Make sure WhatsApp is installed on your device');
    });
};


  return (
  
    <View style={{ flex: 1, }}>
    {/* Header */}
   
    <View style={styles.headerContainer}>
    <View style={styles.leftContainer}>
      <Image source={require('./Pictures1/saibaba1.jpeg')} style={styles.saibabaImage} />
      </View>
   
      <View style={styles.centerContainer}>
        <Image source={require('./Pictures1/lotusyogalogo2.png')} style={styles.logo} />
        <Text style={styles.headerText}>SAI SIDDHA SAMATHI YOGASHRAM</Text>
    
        <Text style={styles.contactText}>Contact: 7401598515</Text>
        <TouchableOpacity style={styles.whatsappContainer} onPress={HandleWhatsappSMS}>
          <Image style={styles.whatsappIcon} source={require('./Pictures1/whatsapp-transparent.png')} />
          <Text style={styles.contactText1}>WhatsApp: 9499010610</Text>
        </TouchableOpacity>
      </View>
   
  
    </View>
 

    <DropdownProvider>
      <NavigationContainer independent={true} linking={linking}>
        <Stack.Navigator initialRouteName="Home"  screenOptions={{ headerShown: false }} style={{backgroundColor:'transparent',fontSize:'bold'}}>
        <Stack.Screen name="Home" component={HomeScreen} />
          <Stack.Screen name="About Us" component={MyTabsHomeCallNext} />
          <Stack.Screen name="Online Services" component={MyTabsOnlineServicesCall} />
          <Stack.Screen name="Offline Services" component={MyTabsOfflineServicesCall} />
          <Stack.Screen name="One On One"  component={MyTabsOneonOneServicesCall} />
          <Stack.Screen name="Recorded Video Service" component={ MyTabsRecordedVideoServicesCall }/>
          <Stack.Screen name="Other Services" component={ MyTabsOtherServicesCall }/>


          <Stack.Screen name="Team" component={ MyTabsTeamCall } />

          <Stack.Screen name="Testimonial" component={ MyTabsTestimonialsCall }/>

          <Stack.Screen name="Locations" component={MyTabsLocationsCall }/>




        </Stack.Navigator>
      </NavigationContainer>
    </DropdownProvider>

</View>

  );
}



const linking = {
  prefixes: ['http://localhost:19006'], // Adjust the prefix based on your setup
  //prefixes: ['http://saisiddhasamathiyogashram.com/'], // Adjust the prefix based on your setup
  config: {
    screens: {

      'Home':'Ho',
      'About Us': 'About-us',
      'Online Services' : 'On',
      'Offline Services' : 'Of',
      'One On One' : 'O',
      'Recorded Video Service' : 'R',
      'Other Services' : 'Os',
      'Teams' : 'Te',
      'Testimonials' : 'Ts',
      'Locations' : 'L'



   
    },
  },
};

function HomeScreen({ navigation }) {
  useEffect(() => {
    document.title = 'Home';  // Set the document title for Home screen
  }, []);
  return (
    <View style={styles.containerScreen}>     
  
      <View style={{alignContent:'center',justifyContent:'center',flexDirection:'column',alignItems:'center',flex:1}}>
  <Image style={{height: windowHeight,width:windowWidth,resizeMode:'center'}} 
   source={require('./Pictures/Ourstory8.jpeg')}/>
   </View>
      <DropdownMenu navigation={navigation} />
      <ScrollView contentContainerStyle={styles.scrollContainer}>
      <MyTabsHomeCall></MyTabsHomeCall>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center', // Align items in the center vertically
    justifyContent: 'center', // Align items in the center horizontally
    padding: 1,
    backgroundColor: '#800080', // Purple background color
  },
  saibabaImage: {
    width: windowWidth * 0.1,
    height: windowHeight * 0.1,
    resizeMode: 'contain',
  },
   centerContainer: {
    flex: 1, // Make the center container take up the remaining space
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: windowWidth * 0.1,
    height: windowHeight * 0.1,
    resizeMode: 'contain',
  },
  headerText: {
    fontSize: windowWidth * 0.0193,
    fontWeight: 'bold',
    color: 'bisque', // White text color
    textAlign: 'center',
  
    },


  contactText: {
    fontSize: windowWidth * 0.015,
    fontWeight: 'bold',
    color: 'bisque', // White text color
    textAlign: 'center',
  },
  contactText1: {
    fontSize: windowWidth * 0.015,
    fontWeight: 'bold',
    color: 'bisque', // White text color
    textAlign: 'center',
    marginTop: 0,
  },
  whatsappContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
  },
  whatsappIcon: {
    height: windowHeight * 0.03,
    width: windowWidth * 0.020,
    resizeMode: 'contain',
    marginRight: 5,
  },
  leftContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdown: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'flex-start',
    padding: 16,
    width: 128,
    color: 'white', // Text color
    backgroundColor: 'rgb(255,228,196)', // Semi-transparent blue background
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 1,
  },
  dropdownContent: {
    width: '100%',
    alignContent:'flex-start',
    backfaceVisibility:'visible',
    backgroundColor:  'rgb(255,228,196)' ,           
    opacity: 0.7
    },
  dropdownItem: {
    color: 'black',
    padding: 12,
    height:48,
    width:120,
    textAlign:'left',
    backgroundColor:   'rgb(255,228,196)' ,      
    alignItems:'center',
    justifyContent:'center',
 
    fontSize: 14,
    fontWeight: 'bold',  
  },
  scrollContainer: {
    flexGrow: 1, // Ensure the content container grows to accommodate the content
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom:20,backgroundColor: 'bisque' 
 
  }, 
});

