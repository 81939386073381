import { Text, View,Image,ScrollView,StyleSheet,FlatList,Dimensions,Platform,TouchableOpacity } from 'react-native';
import {  useNavigation } from '@react-navigation/native';
import MyTabsHomeCallNext from './MyTabsHomeNext';
import MyTabsHomeCall from './MyTabsHome';
const windowWidth = Dimensions.get('window').width;

const data = [
  {
    
    id1: 'BEGINNER ',
    id2: 'Aims to bring flexibility and trains towards Surya Namaskaram and Pranayama',
    id3: 'Course Fee INR:500.00 per session',
   img: require('./Pictures1/One-On-OneBeginner.jpeg') 
  },
  {
  id1: 'FOLLOW UP CLASSES',
  id2: 'Surya Namaskaram,,Pranayama and Aasanas',
  id3: 'Course Fee INR:500.00 per session',
  img: require('./Pictures1/One-On-OneFollowUps1.jpg') 
  },

  {
   
  id1: 'WEIGHT LOSS PROGRAM',
  id2: '3 Month Program that aims in weight loss through Aasanas,Surya Namaskarams,Pranayama and Diet',
  id3: 'Course Fee INR:500.00 per session',
  img: require('./Pictures1/One-On-OneWeightLoss.jpeg') 
  },
 
 

  {
   
    id1: 'AILMENT THERAPY',
    id2: 'Aims to bring relief to ailments like chronic anxiety,neck/shoulder/knee pain,obesity, through Aasanas,Surya Namaskarams,Pranayama and Diet',
    id3: 'Course Fee INR:500.00 per session',
    img: require('./Pictures1/One-On-OneAilment.jpeg') 
    },
   

]


function HomeScreen({ navigation }) {
  return (
    <View style={styles.containerScreen}>
      <DropdownMenu navigation={navigation} />
      <MyTabsHomeCall/>
    </View>
  );
}
const MyTabsOneonOneServicesCall = () => {

const navigation=useNavigation();
  const renderItem = ({ item }) => (
    <View style={styles.item}>
      <Text style={styles.title}>{item.id1}</Text>
      <Image style={styles.image} source={item.img} resizeMode="cover" />
      <Text style={styles.description}>{item.id2}</Text>
      <Text style={styles.price}>{item.id3}</Text>
    </View>
  );

  return (
    <ScrollView style ={{backgroundColor: 'bisque'}}>
      <TouchableOpacity  onPress={() => navigation.navigate('Home')}>
            <Text style={styles.GOHOMESTYLE}>HOME</Text>
          </TouchableOpacity>


      <View style={{backgroundcolor:'red',flexDirection:"column",flex:.16}}>
       <Text style={styles.heading}>ONE ON ONE SERVICES</Text>
      <Text style={styles.subHeading}>SESSIONS ARE CONDUCTED THROUGH ZOOM/STUDIO/AT HOME AS PER CONVENIENCE OF THE LEARNER</Text>
 
      <Text style={styles.subHeading}>(AILMENT THERAPY SUCH AS POSTURE CORRECTION,NECK/KNEE/SHOULDER/BACK PAIN,ETC ARE OFFERED)</Text>
     <Text style={styles.subHeading}>(PRENATAL AND POSTNATAL YOGA SESSIONS AVAILABLE)</Text>
      <Text style={styles.subHeading}>CARE TAKEN FOR STUDENTS WITH AILMENTS</Text>

     
      <View style={styles.timingsContainer}>
          <Text style={styles.timingsheading}>All Sessions are for a duration of 1 hour)</Text>
 
     </View>



</View>

<View style={styles.centerContainer}> {/* Add this View */}
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.id1}
          numColumns={1}
          contentContainerStyle={styles.flatListContainer}
        />
      </View> {/* Close the View */}
</ScrollView>
  );
};

const styles = StyleSheet.create({
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  subHeading: {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 2,
  },
  timings: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },

  item: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    padding: 10,
    maxWidth: windowWidth,
    marginHorizontal: 8,
    marginBottom: 16,
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 5,
  },
  image: {
    width: Platform.OS === 'web' ? 200 : '75%',
    height: Platform.OS === 'web' ? 200 : 200,
    marginBottom: 5,
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 5,
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    borderWidth: 3,
    borderColor: 'brown',
    borderRadius: 20,
    padding: 5,
  },
  flatListContainer: {
    paddingBottom: 20,
  },

  timingsContainer: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 20,
    borderWidth: 3,
    padding: 10,
    marginBottom: 5,
  },
  timingsheading: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 1,
    textAlign: 'center'
  },
  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    textDecorationLine:'underline'
  },
  day: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 'bold',
  },
  centerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  
 GOHOMESTYLE:{
    
  fontSize: 16,
  fontWeight: 'bold',
  
}


});



  export default MyTabsOneonOneServicesCall;