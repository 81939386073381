import { Text, ScrollView,Image,FlatList,View,StyleSheet,Dimensions,Platform,TouchableOpacity} from 'react-native';
import {  useNavigation } from '@react-navigation/native';
const windowWidth = Dimensions.get('window').width;
const data = [
  {
    id: 'L.Selvi Rathi - Yoga Instructor and Coordinator of the Yogashram ',
   img: require('./Pictures1/Instructors/RathijiInstructor1.jpeg'),
   desc:'Popularly called as Rathi Mam, Selva Rathi is a M.Phil(English) graduate. She developed interest in Yoga and earned a  MSc degree in Yoga. She has around 16 years of experience in teaching Yoga. An expert in Reflexology,Massage Therapy,Crystal healing,Astology,Gua Sha,Seed Therapy she is also a co-founder and coordinator of the Yogashram.'
  },
  {
    id: 'Manimekalai - Yoga Instructor',
    img: require('./Pictures1/Instructors/ManiMekalaiMam1.jpeg'),
    desc:'Manimekali is a Siddha doctor and a Yoga degree holder. Her combined knowledge of herbs and Yoga, helps a student to overcome the limitations in doing asanas',
  },
  {
    id: 'Venkatesh - Yoga Instructor',
    img: require('./Pictures1/Instructors/Venkatesh.jpg'),
    desc:'A posture correction expert, Venkatesh is a B.Sc graduate in Yoga. He actively listens to the problems of the students and tries to get into the root of the problem before offering solutions. Popular among students as Venkatesh master, he has an experience of around 16 years in teaching Yoga.'
   },
   {
    id: 'Paramashivan - Yoga Instructor',
    img: require('./Pictures1/Instructors/Paramashivan.jpg'),
    desc:'Paramashivan is a BA and DYNS graduate in Yoga and has a rich experience of teaching yoga for 13 years. He is in charge of the Kamarajar Avenue Studio and he is an all rounder in understanding the problem of the student and giving the appropriate work out for them.',
  },
  {
    id: 'L.Selva Mariappan - Yoga Instructor',
    img: require('./Pictures1/Instructors/Mari.jpg'),
    desc:'Mari sir holds a B.Sc degree in Electronics. His passion for Yoga turned him to a M.Sc (Yoga) graduate. He is popularly known to be a task master and holds 12 years of experience in teaching yoga.'
  },
  {
    id: 'Shanmuga Sundari - Yoga Instructor',
    img: require('./Pictures1/Instructors/SundariInstructor2.jpeg'),
    desc:'Popular amongst students Sundari, she holds a  B.Sc degree in Yoga. Her mere presence in the studio, inspires one towards attempting for a perfect posture. She has around 10 years of experince in teaching yoga.'
  },
   {
    id: 'Nambi Rajan - Yoga Instructor',
    img: require('./Pictures1/Instructors/Nambi3.jpg'),
    desc:'Popularly addressed as Nambi sir, Nambi Rajan holds a DYNS and has around 6 years of experience in teaching Yoga. He is an expert in giving yoga classes for the geriatrics at our studio and their homes.'
  },
    
  
  
   {
    id: 'Jayashree - Yoga Instructor',
    img: require('./Pictures1/Instructors/JayshreeMam1.jpeg'),
    desc:'Jayashree is a B.Sc graduate in Yoga and known for her presence of mind in understanding the problem of the practioner. She holds 6 years of experience in teaching yoga and she is an acupuncturist.'
  },
  
  // Add other video data objects here
];




function HomeScreen({ navigation }) {
  return (
    <View style={styles.containerScreen}>
      <DropdownMenu navigation={navigation} />
      <MyTabsHomeCall />
    </View>
  );
}
const renderItem = ({ item }) => (
  <View style={styles.item}>
    <Text style={styles.title}>{item.id}</Text>
   
    <Image style={styles.image} source={item.img}></Image>
    <Text style={styles.description}>{item.desc}</Text>
   
  </View>
);

function MyTabsTeamCall() {
    //  alert('inside team')
    
        const navigation=useNavigation();
      

  return (
    <ScrollView style={{ backgroundColor: 'bisque' }}>
      <View style={{ padding: 20 }}>
      <TouchableOpacity  onPress={() => navigation.navigate('About Us')}>
            <Text style={styles.GOHOMESTYLE}>HOME</Text>
          </TouchableOpacity>

        <Text style={styles.heading}>OUR TEAM</Text>

        <View style={styles.centerContainer}> {/* Add this View */}
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.id1}
          numColumns={1}
          contentContainerStyle={styles.flatListContainer}
        />
      </View> {/* Close the View */}
    </View>
    </ScrollView>
  );
};
     // alert('end of team')
    
    
    
    

   
const styles = StyleSheet.create({
  item: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
    margin: 8,
    height: 420,
    width: 400,
    borderWidth: 2,
    borderColor: 'burlywood',
    borderRadius: 8,
    
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  flatListContainer: {
    paddingBottom: 20,
  },
  centerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  image: {
    width: Platform.OS === 'web' ? 200 : '75%',
    height: Platform.OS === 'web' ? 200 : 200,
    marginBottom: 5,
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    flexWrap:'wrap',
    width: Platform.OS === 'web' ? 300 : '75%',
    height: Platform.OS === 'web' ? 160 : 160,
    marginBottom: 5,
  },
  

 GOHOMESTYLE:{
    
  fontSize: 16,
  fontWeight: 'bold',
  
}


});

      export default MyTabsTeamCall;
    