import { Text, View,Image,FlatList,StyleSheet,ScrollView,Dimensions,Platform,TouchableOpacity} from 'react-native';
import FUNMODEL from './SampleModel';
import MyTabsHomeCall from './MyTabsHome';
import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
const windowWidth = Dimensions.get('window').width;


const data = [
  {
    id: 'FOOT REFLEXOLOGY',
    img: require('./Pictures1/footreflexology.jpg'),
    desc: "Foot reflexology is the science of activating the vital pressure point on the foot by applying gentle strokes through fingers. It helps in relieving muscles stress and aids in sleeping",
 fee:" INR 1000.00 per session"
  },
  {
    id: 'THERAPEUTIC MASSAGE',
    img: require('./Pictures1/TherapticMaasage.jpeg'),
    desc: "Therapautic massage mobilises soft tissues for inducing relaxation. It helps in reducing pain in specific ailments like neck/shoulder/back/knee pain" ,
 fee:"INR 1200.00 per session "
  },

  {
    id: "ACUPUNCTURE AND  SEED THERAPY",
    img: require('./Pictures1/acupunctureSeedTherapy-New1.jpg'),
    desc: "Acupuncture is inserting needles on the skin at specific lines of energy. Seed therapy is ancient system of healing by sticking native seeds in fingers",
  fee:"INR 300.00 per sitting"
  },

  {
    id: "CUPPING THERAPY ",
    img:require('./Pictures1/cupping.jpg'),
    desc: "Cupping is applying hot or cold vacuum cups in specific areas of the skin  to activate metabolism and immune system",
    fee: "INR 500.00 per sitting"
  },

    // Add more dummy data as needed
];


  const renderItem = ({ item }) => (
    <View style={styles.item}>
      <Text style={styles.title}>{item.id}</Text>
     

      
      <Image style={styles.image} source={item.img}></Image>
      <Text style={styles.description}>{item.desc}</Text>
     
      <Text style={styles.price}>{item.fee}</Text>
      
    </View>
  );



  function HomeScreen({ navigation }) {
    return (
     
      <View style={styles.containerScreen}>
        <DropdownMenu navigation={navigation} />
        <MyTabsHomeCall/>
      </View>
    );
  }

function MyTabsOtherServicesCall() {

  const navigation=useNavigation();
  return (
    <ScrollView style={{ backgroundColor: 'bisque' }}>
      <View style={{ padding: 2 }}>
      <TouchableOpacity  onPress={() =>navigation.navigate('About Us')}>
            <Text style={styles.GOHOMESTYLE}>HOME</Text>
          </TouchableOpacity>

        <Text style={styles.heading}>OTHER SERVICES(AT OUR GANDHI NAGAR STUDIO)</Text>

        <View style={styles.centerContainer}> {/* Add this View */}
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          numColumns={1}
          contentContainerStyle={styles.flatListContainer}
        />
      </View> {/* Close the View */}
    </View>
    </ScrollView>
  );
};


const styles = StyleSheet.create({
  item: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
    margin: 8,
    height: 420,
    width: windowWidth * 0.4,
    borderWidth: 2,
    borderColor: '#ddd',
    borderRadius: 8,
    
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  flatListContainer: {
    paddingBottom: 20,
  },
  centerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 5,
  },
  image: {
    width: windowWidth * 0.4,
    height: 200,
    marginBottom: 5,
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    flexWrap:'nowrap',
  
    marginBottom: 5,
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    borderWidth: 3,
    borderColor: 'brown',
    borderRadius: 20,
    padding: 5,
  },
  

 GOHOMESTYLE:{
    
  fontSize: 16,
  fontWeight: 'bold',
  
}

});





  export default MyTabsOtherServicesCall;