import React from 'react';
import { Text, View, Image, FlatList, StyleSheet, ScrollView, Dimensions, Platform,TouchableOpacity } from 'react-native';
import {  useNavigation } from '@react-navigation/native';
import MyTabsHomeCall from './MyTabsHome';
const windowWidth = Dimensions.get('window').width;

const data = [
  {
    id1: 'BEGINNER',
    id2: 'Aims to bring flexibility and trains towards Surya Namaskaram and Pranayama',
    id3: 'Course Fee INR:2000.00',
    img: require('./Pictures1/Beginner-Offline.png')
  },
  {
    id1: 'FOLLOW UP CLASSES',
    id2: 'Surya Namaskaram,,Pranayama and Aasanas',
    id3: 'Course Fee INR:1600.00 per month',
    img: require('./Pictures1/studio grp class1.jpeg')
  },
  {
    id1: 'WEIGHT LOSS PROGRAM',
    id2: '3 Month Program that aims in weight loss through asanas,Surya Namaskarams,Pranayama and Diet',
    id3: 'Course Fee INR:2000.00 per month',
    img: require('./Pictures1/Offline-WeightLoss.jpeg')
  },
];

const renderItem = ({ item }) => (
  <View style={styles.item}>
    <Text style={styles.title}>{item.id1}</Text>
    <Image style={styles.image} source={item.img} resizeMode="cover" />
    <Text style={styles.description}>{item.id2}</Text>
    <Text style={styles.price}>{item.id3}</Text>
  </View>
);


function HomeScreen({ navigation }) {
  return (
    <View style={styles.containerScreen}>
      <DropdownMenu navigation={navigation} />
      <MyTabsHomeCall/>
    </View>
  );
}

function MyTabsOfflineServicesCall() {
  const navigation=useNavigation();
  return (
    <ScrollView style={{ backgroundColor: 'bisque' }}>
       <TouchableOpacity  onPress={() => navigation.navigate('Home')}>
            <Text style={styles.GOHOMESTYLE}>HOME</Text>
          </TouchableOpacity>
      <View style={{ padding: 20 }}>
        <Text style={styles.heading}>OFFLINE SERVICES(AT OUR STUDIOS)</Text>
        <Text style={styles.subHeading}>(AILMENT THERAPY SUCH AS POSTURE CORRECTION,NECK/KNEE/SHOULDER/BACK PAIN,ETC ARE OFFERED)</Text>
        <Text style={styles.subHeading}>(PRENATAL AND POSTNATAL YOGA SESSIONS AVAILABLE)</Text>
        <Text style={styles.subHeading}>CARE TAKEN FOR STUDENTS WITH AILMENTS</Text>

      
        <View style={styles.timingsContainer}>
          <Text style={styles.timingsheading}>All Sessions are available at the following timings:(Session duration-1 hour)</Text>
          <View style={styles.daysContainer}>
            <Text style={styles.day}>Monday-Friday</Text>
            <Text style={styles.day}>Saturdays</Text>
          </View>

          <View style={styles.day}>
          <Text style={styles.day}>5am-11.30am</Text>
            <Text style={styles.day}>5am-9am</Text>
          </View>
          
          <View style={styles.day}>
          <Text style={styles.day}>5pm-7pm</Text>
            <Text style={styles.day}>Not Available</Text>
          </View>
        </View>
 </View>





 <View style={styles.centerContainer}> {/* Add this View */}
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.id1}
          numColumns={1}
          contentContainerStyle={styles.flatListContainer}
        />
      </View> {/* Close the View */}

    
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  subHeading: {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 2,
  },
  timings: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },

  item: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    padding: 10,
    maxWidth:  windowWidth - 20,
    marginHorizontal: 8, // Adjust horizontal margin
    marginBottom: 16, // Adjust vertical margin
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 5,
  },
  image: {
    width: Platform.OS === 'web' ? 200 : '75%',
    height: Platform.OS === 'web' ? 200 : 200,
    marginBottom: 5,
  },
  description: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 5,
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    borderWidth: 3,
    borderColor: 'brown',
    borderRadius: 20,
    padding: 5,
  },
  flatListContainer: {
    paddingBottom: 20,
  },

  timingsContainer: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 20,
    borderWidth: 3,
    padding: 30,
    marginBottom: 10,
  },
  timingsheading: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'center'
  },
  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    textDecorationLine:'underline'
  },
  day: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 'bold',
  },
  centerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  GOHOMESTYLE:{
    
    fontSize: 16,
    fontWeight: 'bold',
    
  }
});

export default MyTabsOfflineServicesCall;
