import React, { useState, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Dimensions } from 'react-native';
import { Video } from 'expo-av';
import { Ionicons } from '@expo/vector-icons';
import {  useNavigation } from '@react-navigation/native';

import { ScrollView } from 'react-native-gesture-handler';

import MyTabsHomeCall from './MyTabsHome';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const data = [
  {
    id: 'Anand - Partner in Aravind Petroleum',
    video1: require('./Pictures1/Testimonials/Feedback-Anand-Sir-Resized8.mp4'),
  },
  {
    id: 'Poornima Shankar - NGO Volunteer',
    video1: require('./Pictures1/Testimonials/Feedback-Poornima-Resized3.mp4'),
  },
  {
    id: 'Divya Kode - Entrepreneur',
    video1: require('./Pictures1/Testimonials/Feedback_Divya-Mam-Resized4.mp4'),
  },
  {
    id: 'Anuradha - Media Personality',
    video1: require('./Pictures1/Testimonials/Feedback-Anuradha-Resized9.mp4'),
  },
  {
    id: 'Adithya Shanmugam - Banker',
    video1: require('./Pictures1/Testimonials/Feedback-Adi-Resized.mp4'),
  },
  {
    id: 'Keerthi Vasan - Rgnl Dir - EFF Ltd.',
    video1: require('./Pictures1/Testimonials/Feedback_KeerthiVasan-Resized1.mp4'),
  },
  {
    id: 'Indu Shanmugam - Architect',
    video1: require('./Pictures1/Testimonials/Feedback-Indu-Resized1.mp4'),
  },
  {
    id: 'Mohan - Stock Market Expert',
    video1: require('./Pictures1/Testimonials/Feedback-Mohan-Resized.mp4'),
  },
  {
    id: 'Lalitha Suresh - Teacher',
    video1: require('./Pictures/Feedback-Lalitha-Resized.mp4'),
  },
  {
    id: 'Hemalatha Seshadri - Teacher',
    video1: require('./Pictures1/Testimonials/Feedback-Hema-Resized.mp4'),
  },
  {
    id: 'Vijayalakshmi - Music Teacher',
    video1: require('./Pictures1/Testimonials/Feedback-Viji-Resized2.mp4'),
  },
];


export default function MyTabsTestimonialsCall() {
  const navigation = useNavigation();
  const videoRefs = useRef([]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);

  const handleTogglePlayback = (index) => {
    if (selectedVideoIndex !== null && selectedVideoIndex !== index) {
      videoRefs.current[selectedVideoIndex].pauseAsync();
    }
    setSelectedVideoIndex(selectedVideoIndex === index ? null : index);
    if (selectedVideoIndex === index) {
      videoRefs.current[index].pauseAsync();
    } else {
      videoRefs.current[index].playAsync();
    }
  };

  return (
    <ScrollView style={{ backgroundColor: 'bisque' }}>
      <View style={styles.header}>
      <TouchableOpacity  onPress={() => navigation.navigate('Home')}>
            <Text style={styles.GOHOMESTYLE}>HOME</Text>
          </TouchableOpacity>
      </View>
      <View style={styles.container}>
        <Text style={styles.heading}>TESTIMONIALS OF STUDENTS</Text>
        {data.map((item, index) => (
          <View key={index} style={styles.item}>
            <Text style={styles.title}>{item.id}</Text>
            <Video
              ref={(ref) => (videoRefs.current[index] = ref)}
              source={item.video1} // Using require for local files
              style={styles.video}
              resizeMode="cover"
              useNativeControls={false}
              isLooping
              shouldPlay={selectedVideoIndex === index}
            />
            <View style={styles.controls}>
              <TouchableOpacity onPress={() => handleTogglePlayback(index)}>
                <Ionicons
                  name={selectedVideoIndex === index ? 'pause-circle' : 'play-circle'}
                  size={36}
                  color="black"
                />
              </TouchableOpacity>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'bisque',
    padding: 10,
    alignItems: 'left',
  },
  GOHOMESTYLE: {
    fontSize: 16,
    color: 'black',
    fontWeight: 'bold',
    textAlign:'left'
  },
  container: {
    flex: 1,
    padding: 10,
     alignContent:'center',
    alignItems:'center',
    width:'100%',
   backgroundColor:'burlywood'
  },

  
  heading: {
    fontSize: 18,
    textAlign: 'center',
    marginVertical: 20,
    fontWeight: 'bold',
  },
  item: {
    fontSize: 18,
    textAlign: 'center',
    marginVertical: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    width:windowWidth,
    height:windowHeight * .59,
    alignContent:'center',
    alignItems:'center',
    alignSelf:'center',
    backgroundColor:'mistyrose',
  },

  title: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: 'bold',
    alignContent:'center',
    alignItems:'center'
  },
  video: {
    width: '65%', 
    height: windowHeight*.5,
    alignContent:'center',
    alignItems:'center',
    aspectRatio:16/9,
    marginBottom:5,
    marginTop:5,
    
  },

  controls: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
     alignContent:'center',
    alignItems:'center'
  },
});
